<template>
     <div class=" service body">
          <div class="container">
               <div class="columns">
                    <div class="column is-half">
                         <div class="vertical-align">
                              <div class="section-header-2">Artificial Intelligence Of Things <br />IoT | IIoT | AIoT</div>
                              <p class="">
                                   The IT landscape is being revolutionised by big data, IoT, IIoT, AI, cloud, automation and machine learning.
                                   Enterprise business models are adapting to these changes with a better understanding of these technologies, agile
                                   design principles, strategic technological and infrastructural approaches.
                              </p>
                         </div>
                    </div>
                    <div class="column is-half"><img class="sideimg" src="@/assets/images/services/aiot.png" alt="" /></div>
               </div>

               <div class="columns details">
                    <div class="column is-half ">
                         <div class="line-top">
                              <!-- <div class="section-title">
                                   Benefits of ITeS System
                              </div> -->
                              <div>
                                   <p class="mt-20">
                                        Our technology consultants leverage deep domain expertise and innovative solutions to help your business with
                                        long and short-term strategies that align with your IT environment, enterprise goals and policies. CTL IOT and
                                        AI solutions will help your business to integrate all your enterprise IoT, IIoT, AI and machine learning
                                        Services into one unique, easy-to-use platform. It will help in increasing the productivity, effective
                                        utilisation of IT infrastructure, and reduce the cost, complexity and downtime related to business-critical
                                        services. Our Artificial Intelligence of Things (AIoT) solutions is the combination of artificial intelligence
                                        (AI) technologies with the Internet of Things (IoT) infrastructure to achieve more efficient IoT operations,
                                        improve human-machine interactions and enhance data management and analytics. AI can be used to transform IoT
                                        data into useful real time information for improved decision making processes, thus creating a foundation for
                                        newer technology such as IoT Data as a Service (IoT DaaS).
                                   </p>
                              </div>
                         </div>
                    </div>
                    <div class="column is-half ">
                         <div class="line-top">
                              <!-- <div class="section-title">
                                   Specific Solutions
                              </div> -->
                              <p class="mt-20">
                                   AIoT is transformational and mutually beneficial for both types of technology as AI adds value to IoT through
                                   machine learning capabilities and IoT adds value to AI through connectivity, signalling and data exchange. Our
                                   proposed AIoT could be a viable solution to solve existing operational problems, such as the expense associated
                                   with effective human capital management (HCM) or the complexity of supply chains and delivery models.
                              </p>

                              <div class="para-large mt-60">
                                   View Projects that have availed CTLs Solution in this category.
                                   <br />
                                   <router-link :to="{ name: 'PROJECTS' }" class="knowmore">Know More</router-link>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</template>

<script>
     export default {};
</script>

<style lang="scss" scoped></style>
